import React from 'react';
import PropTypes from 'prop-types';


import VisibilityIcon from './images/eye_on.svg';
import VisibilityOffIcon from './images/eye_off.svg';

import css from './ShowPasswordButton.module.css';

const ShowPasswordButton = ({ showPassword, toggleShowPassword }) => (
  <button
    type="button"
    className={css.showPasswordButton}
    onClick={() => toggleShowPassword(!showPassword)}
  >
    {showPassword ? <img src={VisibilityOffIcon} /> : <img src={VisibilityIcon} />}
  </button>
);

ShowPasswordButton.propTypes = {
  showPassword: PropTypes.bool.isRequired,
  toggleShowPassword: PropTypes.func.isRequired,
};

export default ShowPasswordButton;
